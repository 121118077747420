<template>
  <div>
    <CRow>
      <CCol lg="12">
        <div style="margin-bottom: 10px">
          <CForm ref="search_form" inline @submit.prevent="searchMeeting">
            <CInput placeholder="Search" v-model="search_text" size="lg" />
            <CButton
              color="light"
              size="lg"
              style="margin-left: 5px"
              v-on:click="searchMeeting"
            >
              <font-awesome-icon icon="search" />
            </CButton>
          </CForm>
        </div>
        <div class="user-table">
          <div class="table-content">
            <CDataTable
              :items="user_data"
              :fields="fields"
              hover
              :loading="loading"
              @row-clicked="rowClickHandler"
              :sorter="{ external: true, resetable: true }"
              :sorterValue.sync="sorterValue"
            >
            <template #createdAt="{ item }">
              <td>
                  {{moment(item.createdAt).format('LLL')}}
              </td>
            </template>
              <template #user="{ item }">
              <td>
              {{item.userId!==null?item.userId.username: item.userId }}
              </td>
            </template>
            <template #user_id="{ item }">
              <td>
              {{item.userId!==null?item.userId._id: item.userId }}
              </td>
            </template>
            <template #log_id="{ item }">
              <td>
              {{item._id}}
              </td>
            </template>
            </CDataTable>
          </div>

          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </div>
      </CCol>
    </CRow>
    <div
      style="
        position: absolute;
        top: 120px;
        right: 33px;
        z-index: 9999;
        height: 45px;
        min-width: 300px;
        border-radius: 10px;
      "
    >
      <CAlert
        style="color: red; background-color: white"
        :show.sync="currentAlertCounter"
        closeButton
      >
        Cannot find user!
      </CAlert>
    </div>
  </div>
</template>


<script>
import CTableWrapper from "../base/Table.vue";
import ActivitiesServiceApi from "../../services/api/activities";

const fields = ["user","ipAddress","action","description","log_id","createdAt"];

export default {
  name: "ViewUser",
  components: { CTableWrapper },
  data() {
    return {
      fields,
      currentPage: 1,
      limit: 0,
      totalPages: 0,
      user_data: [],
      loading: true,
      search_text: null,
      currentAlertCounter: 0,
      sorterValue: { asc: true, column: null },
    };
  },

  mounted() {
    ActivitiesServiceApi.getAllActivities().then(
      (response) => {
        this.loading = false;
        this.user_data = response.data.docs;
        this.limit = response.data.limit;
        this.totalPages = response.data.totalPages;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  watch: {
    currentPage: function (val) {
      console.log(val);
      this.loading = true;
      if (this.search_text && this.search_text.length) {
         ActivitiesServiceApi.getAllActivities(
          this.search_text,
          this.currentPage,
        ).then(
          (response) => {
            if (response.data.message) {
              this.loading = false;
              this.user_data = response.data.message.docs;
            }
          },
          (error) => {
            this.loading = false;
            this.currentAlertCounter = 3;
          }
        );
      } else {
          ActivitiesServiceApi.getAllActivities(
          this.search_text,
          this.currentPage,
        ).then(
          (response) => {
            this.loading = false;
            this.user_data = response.data.docs;
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    // sorterValue: function (val) {
    //   this.loading = true;
    //   if (this.search_text && this.search_text.length) {
    //     UserServiceApi.searchUser(this.search_text, 1, this.sorterValue).then(
    //       (response) => {
    //         if (response.data.message) {
    //           console.log(response.data.message);
    //           this.loading = false;
    //           this.currentPage = 1;
    //           this.user_data = response.data.message.docs;
    //           this.totalPages = response.data.message.totalPages;
    //         }
    //       },
    //       (error) => {
    //         this.loading = false;
    //         this.currentAlertCounter = 3;
    //       }
    //     );
    //   } else {
    //     UserServiceApi.getAllUsers(this.currentPage, 10, this.sorterValue).then(
    //       (response) => {
    //         this.loading = false;
    //         this.user_data = response.data.docs;
    //       },
    //       (error) => {
    //         this.loading = true;
    //         this.currentAlertCounter = 3;
    //       }
    //     );
    //   }
    // },
  },
  methods: {
    rowClickHandler(item, index, column, event) {
      const id = item._id;
      //this.$router.push({ name: "User details", params: { id } });
    },
    searchMeeting() {
      this.loading = true;
      if (this.search_text && this.search_text.length) {
        MeetingServiceApi.searchMeeting(this.search_text).then(
          (response) => {
            if (response.data.message) {
              this.loading = false;
              this.currentPage = 1;
              this.user_data = response.data.message.docs;
              this.totalPages = response.data.message.totalPages;
            }
          },
          (error) => {
            this.loading = false;
            this.search_text = null;
            this.currentAlertCounter = 3;
          }
        );
      } else {
        MeetingServiceApi.getAllMeeting().then(
          (response) => {
            this.loading = false;
            this.currentPage = 1;
            this.user_data = response.data.docs;
            this.totalPages = response.data.totalPages;
          },
          (error) => {
            this.loading = false;
            this.currentAlertCounter = 3;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.user-table {
  background-color: #fff;
  padding: 20px;
  margin: 0 10px 10px 10px 10px;
  border-radius: 20px;
}
.table-content {
  min-height: 550px;
}
.meeting-password-hide{
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 5px 10px;
}
.passord-field{
  text-align: center;
  display: block;
  width: 100%;
}

</style>