import axios from 'axios'
import authHeader from '../auth/auth-header'


const API_URL = 'https://development.space-meets.com/api/'

class  ActivitiesServiceApi {
  getAllActivities(userId=null,page = 1, limit = 10) {
    return axios.get(API_URL + 'user/getAllActivities', {
      params: {
        page: page,
        limit: limit,
        userId:userId
      },
      headers: authHeader()
    });
  }
}

export default new ActivitiesServiceApi()